import React from 'react'
import { Link } from 'gatsby'
import Block from './Block'
import styled from 'styled-components';

export const Menu = () => (
	<MenuWrapper>
		<Block size="medium">
			<ul>
				<li>
					<span>✏️</span> <Link to="/" activeClassName='active'> Latest in English</Link>
				</li>
				<li>
					<span>🌑</span> <Link to="/ru/" activeClassName='active'>Все на русском</Link>
				</li>
				<li>
					<span>🇺🇦</span> <Link to="/hui-voine/ru" activeClassName='active'>Нет<br/>войне!</Link>
				</li>
				<li>
					<span>📖</span> <Link to="/reading/ru/" activeClassName='active'> Дневник чтения (ru)</Link>
				</li>
			</ul>
		</Block>
	</MenuWrapper>
)

const MenuWrapper = styled.div`
margin-top:-16px;
	
	ul {
		list-style:none;
		font-family: var(--fontFamily-sans);
		font-weight:bold;
		font-size:1rem;
		margin-bottom:0;
		display:flex;
		//justify-content:center;

		li {
      display:inline-block;
      margin-right:24px;
      width:90px;
	  span {
		  font-size:20px;
	  }
    }

    a {
      display:block;
	  margin-top:0.25rem;
      line-height:1.4;
      opacity:0.5;
      text-decoration:none;

      &:hover {
        opacity:1;
        text-decoration:underline;
      }
    }

		a.active {
			text-decoration:none;
      cursor:default;
      opacity:1;
		}
	}
`;