import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import Tags from '../../components/Tags';
import Block from '../../components/Block';
import { Menu }  from '../../components/Menu';
import kebabCase from "lodash/kebabCase"
import wavePattern from '../../images/wave-pattern.svg';

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || 'Title';
	const posts = data.allMarkdownRemark.nodes;

	return (
		<Layout location={location} title={siteTitle}>
			<Menu/>
			<Block size="small">
				<WritingContainer>
					<ol style={{ listStyle: 'none' }}>
						{posts.map((post) => {
							const title = post.frontmatter.title || post.fields.slug;

							return (
								<li key={post.fields.slug}>
									<article
										className="post-list-item"
										itemScope
										itemType="http://schema.org/Article"
									>
										<header>
											<Tags>
												<ul>
													{post.frontmatter.tags.map(tag => (
														<li key={tag}>
															<Link to={`/tags/${kebabCase(tag)}/`}>
															#{tag}
															</Link>
														</li>
													))}
												</ul>
											</Tags>
											<h1>
												<Link to={post.fields.slug} itemProp="url">
													<span itemProp="headline">{title}</span>
												</Link>
											</h1>	
											<p className="author label">{post.frontmatter.date}</p>									
										</header>
										<section
											dangerouslySetInnerHTML={{ __html: post.html }}
											itemProp="articleBody"
											className="articleBody"
										/>
									</article>
									<div className="wave"></div>
								</li>
							);
						})}
					</ol>
				</WritingContainer>
			</Block>
		</Layout>
	);
};

export default BlogIndex;

const WritingContainer = styled.div`
	.post-list-item {
	margin: var(--spacing-8) auto var(--spacing-8);
		header {
			margin-bottom: var(--spacing-4);
		}
	}

	.wave {
	height: 32px;
	width: 100%;
	max-width: 1000px;
	margin: 80px auto 64px;
	background:url(${wavePattern});
	background-repeat: repeat-x;
	}

	li:last-child .wave {
		display: none;
	}
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
		sort: { fields: [frontmatter___date], order: DESC }
		filter: { frontmatter: { templateKey: { eq: "reading-diary-ru" } } }
	) {
      nodes {
        excerpt
		html
        fields {
          slug
        }
        frontmatter {
			title
			date(formatString: "D MMM, YYYY")
			description
			tags
			author
        }
      }
    }
  }
`;
